<template>
  <div class="home-index frame-content-common-2022-db-orderBiz">
    <div class="dashboard-orderBiz">
      <el-row>
        <el-col :span="18" class="content-left">
          <div class="header-to-home-order d-flex justify-content-center">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center title-top-home">
                <div class="d-flex flex-column pr-2 text-right">
                  <span>Hotline:</span>
                  <span class="color-red">1900 6825</span>
                </div>
                <img src="@/static/images/home/icon-tv.svg" alt="">
              </div>
              <!-- search -->
              <div
                class="seach-china d-flex align-items-center"
                :class="showSelectMall ? 'add-border-99' : ''"
              >
                <div
                  class="select-mall d-flex align-items-center"
                  @click.stop="showSelectMall = true"
                  @mouseleave="mouseLeaveSelectMall"
                >
                  <div class="image">
                    <img
                      src="@/static/images/header-top/taobao.png"
                      v-if="key_mall == 'sync_taobao'"
                    />
                    <img
                      src="@/static/images/header-top/1688.png"
                      v-if="key_mall == 'sync_1688'"
                    />
                    <!-- <img
                      src="@/static/images/header-top/baidu.png"
                      v-if="mallActive == 2"
                    />

                    <img
                      src="@/static/images/header-top/tmall.png"
                      v-if="mallActive == 4"
                    /> -->
                  </div>
                  <img src="@/static/icon/header-top/down2.svg" />
                  <div class="sub-select-mall" v-if="showSelectMall">
                    <div class="image" @click.stop="changeMall('sync_taobao')">
                      <img src="@/static/images/header-top/taobao.png" />
                    </div>
                    <div class="image" @click.stop="changeMall('sync_1688')">
                      <img src="@/static/images/header-top/1688.png" />
                    </div>
                    <!-- <div class="image" @click.stop="changeMall(2)">
                      <img src="@/static/images/header-top/baidu.png" />
                    </div>

                    <div class="image" @click.stop="changeMall(4)">
                      <img src="@/static/images/header-top/tmall.png" />
                    </div> -->
                  </div>
                </div>

                <!-- danh mục  -->
                <div class="list-category">
                  <nav>
                    <ul>
                      <li class="icon-down">
                        <i class="el-icon-caret-bottom"></i>
                        <ul>
                          <li v-for="item in dataListCate" :key="item.id" class="nav-lv">
                            <div class="d-flex justify-content-between align-items-center">
                              <span>{{ item.name }}</span>
                              <span><i v-if="item.child.length > 0" class="el-icon-arrow-right"></i></span>
                            </div>
                            <!-- <span>{{ item.name }}</span> -->
                            <ul>
                              <li v-for="i in item.child" :key="i.id" class="nav-lv" @click="showKeySearch(i)">
                                <div class="d-flex justify-content-between align-items-center">
                                  <span>{{ i.name }}</span>
                                  <span><i v-if="i.child.length > 0" class="el-icon-arrow-right"></i></span>
                                </div>
                                <ul>
                                  <li v-for="y in i.child" :key="y.id" class="nav-lv" @click="showKeySearch(y)">
                                    <span>{{ y.name }}</span>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>

                <!-- <input
                  type="text"
                  v-model="search"
                  placeholder="Chào bạn, bạn muốn tìm gì hôm nay ?"
                /> -->

                <el-input
                  type="text"
                  v-model="search"
                  placeholder="Chào bạn, bạn muốn tìm gì hôm nay ?"
                  @keyup.enter.native="searchProduct()"
                ></el-input>

                <div class="seach-button" @click=searchProduct()>
                  <span class="btn-18">
                    <img src="@/static/icon/header-top/search.svg" />
                  </span>
                </div>
              </div>
              <!--  -->
              <div class="d-flex align-items-center title-top-home">
                <img src="@/static/images/home/rame.svg" alt="">
                <div class="d-flex flex-column pl-2">
                  <span>Tỷ giá hôm nay:</span>
                  <span class="color-red">{{ configList.exchange_rate | vnd2 }}  đ / ¥</span>
                </div>
              </div>
            </div>
          </div>
          <!-- nút chuyển màn -->
          <div class="content-button-home">
            <div class="d-flex justify-content-center align-items-center">
              <div class="box-content" @click="showOrder()">
                <div class="image">
                  <img src="@/static/images/home/img-10.svg" alt="">
                </div>
                <span>Tạo đơn hàng</span>
              </div>
              <div class="box-content" @click="showOrderConsignment()">
                <div class="image">
                  <img src="@/static/images/home/img-4.svg" alt="">
                </div>
                <span>Tạo đơn hàng <br> ký gửi</span>
              </div>
              <div class="box-content" @click="showOrderlist()">
                <div class="image">
                  <img src="@/static/images/home/img-9.svg" alt="">
                </div>
                <span>Đơn hàng</span>
              </div>
              <div class="box-content" @click="showPacklist()">
                <div class="image">
                  <img src="@/static/images/home/img-3.svg" alt="">
                </div>
                <span>Kiện hàng</span>
              </div>
              <div class="box-content" @click="showFee()">
                <div class="image">
                  <img src="@/static/images/home/img-5.svg" alt="">
                </div>
                <span>Tra cước phí</span>
              </div>
            </div>
            <!--  -->
            <div class="d-flex justify-content-center align-items-center pt-5">
              <div class="box-content" @click="showCart()">
                <div class="image">
                  <img src="@/static/images/home/img-6.svg" alt="">
                </div>
                <span>giỏ hàng</span>
              </div>
              <div class="box-content" @click="showTran()">
                <div class="image">
                  <img src="@/static/images/home/img-7.svg" alt="">
                </div>
                <span>tài khoản</span>
              </div>
              <div class="box-content" @click="showComplain()">
                <div class="image">
                  <img src="@/static/images/home/img-8.svg" alt="">
                </div>
                <span>khiếu nại</span>
              </div>
              <div class="box-content" @click="showSupplier()">
                <div class="image">
                  <img src="@/static/images/home/img-1.svg" alt="">
                </div>
                <span>nhà cung cấp</span>
              </div>
              <div class="box-content" @click="showDelivery()">
                <div class="image">
                  <img src="@/static/images/home/img-2.svg" alt="">
                </div>
                <span>giao hàng</span>
              </div>
            </div>
          </div>
          <!-- công cụ đặt hàng -->
          <div class="d-flex align-items-center justify-content-center content-bottom-home">
            <a href="https://chromewebstore.google.com/detail/c%C3%B4ng-c%E1%BB%A5-h%E1%BB%97-tr%E1%BB%A3-%C4%91%E1%BA%B7t-h%C3%A0ng-h/fleifcognemdgekfknmnbelghpgbpkek?authuser=0&hl=vi" target="_blank" class="d-flex align-items-center box">
              <div class="textTitle d-flex flex-column">
                <span class="bold">Cài đặt</span>
                <span>Công cụ đặt hàng</span>
              </div>
              <img src="@/static/images/home/gg.svg" alt="" />
            </a>
            <div>
              <img src="@/static/images/home/ine.svg" alt="" />
            </div>
            <div class="d-flex align-items-center box">
              <div class="textTitle d-flex flex-column text-right">
                <span class="bold">Tải mobile app</span>
                <span>đặt hàng</span>
              </div>
              <div class="d-flex flex-column pl-2">
                <img src="@/static/images/home/appstore.svg" alt="" />
                <a href="https://play.google.com/store/apps/details?id=com.hangve&pli=1" target="_blank">
                  <img src="@/static/images/home/googleplay.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="content-right-home">
          <div class="content-right-db">
            <div class="title">
              <span>HƯớng dẫn trợ giúp</span>
            </div>
            <div class="search mt-2">
              <el-input
                v-model="inputSearch"
                placeholder="Tìm kiếm bài viết trợ giúp"
                @keyup.enter.native="searchGuide()"
              ></el-input>
            </div>
            <div class="content-search mt-2" id="guide-list">
              <div v-for="i in listPost" :key="i.text">
                <div class="d-flex align-items-center contentPost">
                  <div style="width: 34px;" class="circle d-flex align-items-center justify-content-center">
                    <img src="@/static/images/home/right.svg" alt="" />
                  </div>
                  <div style="width: 90%;">
                    <!-- <span>{{ i.text }}</span> -->

                    <el-link class="line-1" :href="i.link" target="_blank">
                      <span>{{ i.title }}</span>
                    </el-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import store from "@/store";
export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "Trang chủ :: Hệ thống vận chuyển",
    };
  },
  components: {},
  data() {
    return {
      loading: true,
      inputSearch: "",
      // Ô Seach
      search: "",
      showSelectMall: false,
      mallActive: 'sync_taobao',
      filter: {
        link: "https://hangve.com/huong-dan/feed/",
        search: "",
        page: 1
      },
      // bài viết
      listPost: [],
      //
      dataListCate: [],
    };
  },

  computed: {
    ...mapGetters("account", ["configList"]),
    ...mapGetters(["key_mall"]),
  },

  mounted() {
    this.fetchCategory();
    this.fetchGuide();

    // Event scroll guide list
    const guideSelector = document.querySelector('#guide-list');
    // eslint-disable-next-line no-unused-vars
    guideSelector.addEventListener('scroll', e => {
      if (guideSelector.scrollTop + guideSelector.clientHeight >= guideSelector.scrollHeight) {
        this.fetchGuide();
      }
    });
  },
  methods: {
    showKeySearch(i) {
      this.search = i.name
      if(this.search != "") {
        this.$router.push({
          name: "search-list",
          params: {
            key_search: i.name,
          },
        })
        this.search = "";
      }
    },
    searchProduct() {
      if(this.search != "") {
        this.$router.push({
          name: "search-list",
          params: {
            key_search: this.search,
          },
        })
        this.search = "";
      } else {
        this.$notify({
          title: "Vui lòng nhập đầy đủ thông tin",
          type: 'warning',
          message: "Vui lòng nhập từ khóa để tìm kiếm !",
        });
      }
    },
    searchGuide() {
      if (this.inputSearch !== this.filter.search) {
        this.filter.search = this.inputSearch;
        this.filter.page = 1;
        this.listPost = [];
        this.fetchGuide();
      }
    },
    // ô search
    mouseLeaveSelectMall() {
      this.showSelectMall = false;
    },
    changeMall(mall) {
      this.showSelectMall = false;
      this.mallActive = mall;
      store.dispatch("setKeyMall", mall);
    },
    // chuyển màn
    showOrder() {
      this.$router.push({ name: "order-create" });
    },
    showOrderConsignment() {
      this.$router.push({ name: "consignment-create" });
    },
    showOrderlist() {
      this.$router.push({ name: "order-list" });
    },
    showPacklist() {
      this.$router.push({ name: "pack-list" });
    },
    showCart() {
      this.$router.push({ name: "cart-create" });
    },
    showTran() {
      this.$router.push({ name: "transaction-list" });
    },
    showComplain() {
      this.$router.push({ name: "complain-list" });
    },
    showSupplier() {
      this.$router.push({ name: "supplier-list" });
    },
    showDelivery() {
      this.$router.push({ name: "delivery-list" });
    },
    showFee() {
      this.$router.push({ name: "check-fees" });
    },
    // ds category
    fetchCategory() {
      axios.get("item/get-category").then((response) => {
        switch (response.code) {
          case 200:
            this.dataListCate = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // List Guide
    fetchGuide() {
      axios.get("rss/guide", {params: this.filter}).then((response) => {
        switch (response.code) {
          case 200:
            response.data.forEach((value) => {
              this.listPost.push(value);
            });
            this.filter.page = response.data.length > 0 ? this.filter.page + 1 : this.filter.page;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
        if (this.filter.page === 2) this.fetchGuide();
      });
    },
  },
  watch: {},
};
</script>

<style lang="css" scoped>
/* top-home */
.header-to-home-order  {
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url("@/static/images/home/layer.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.header-to-home-order .title-top-home {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}
.header-to-home-order .text-right {
  text-align: right;
}
.header-to-home-order .color-red {
  color: #da0606;
  padding-top: 5px;
  font-weight: 700;
}
/* chuyển màn */
.content-button-home {
  background: #EDF4FB;
  padding-top: 60px;
  padding-bottom: 75px;
}
.content-button-home .box-content {
  margin-right: 40px;
}
.content-button-home .box-content .image {
  cursor: pointer;
  width: 120px;
  height: 120px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
.content-button-home .box-content .image:hover {
  background: #1D60A6;
}
.content-button-home .box-content {
  height: 160px;
  width: 120px;
  text-align: center;
}
.content-button-home .box-content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #575757;
  padding-top: 10px;
}
/* công cụ đặt hàng */
.content-bottom-home {
  background: #fff;
  padding: 36px 0 50px 0;
}
.content-bottom-home .box {
  margin: 0 100px;
  text-decoration: none;
  color: #1D60A6;
}
.content-bottom-home {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #1D60A6;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.content-bottom-home .bold {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.content-bottom-home .text-right {
  text-align: right;
}
/* content right db */
.content-right-db {
  padding: 25px 30px 0 16px;
}
.content-right-db .title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #1D60A6;
  text-transform: uppercase;
}
</style>
<style>
.dashboard-orderBiz {
  background-color: #f6faff;
  height: 100%;
}
.dashboard-orderBiz .content-left {
  border-right: 1px solid #C7C7C7;
  min-height: calc(100vh - 50px);
  position: relative;
  padding-bottom: 200px;
  /* border-bottom: 5px solid #1D60A6; */
}
.content-right-home {
  height: 100vh;
}
/*// Ô seach đa chức năng*/
.header-to-home-order .seach-china {
  margin-left: 20px;
  margin-right: 20px;
  width: 500px;
  height: 35px;
  border-radius: 25px;
  background-color: #fff;
  border: 1px solid #BFD5EC;;
}
.select-mall {
  height: 35px;
  padding-left: 13px;
  padding: 0px 10px 0px 20px;
  border-right: 1px solid #bfd5ec;
  /* margin-right: 8px; */
  cursor: pointer;
  position: relative;
  width: 120px;
}
.select-mall .image img {
  height: 22px;
  margin-right: 5px;
}
.seach-china input {
  width: 100%;
  height: 30px;
  border: none;
  font-size: 13px;
  color: #333;
  margin-right: 8px;
  outline: none;
}
.seach-china input::-webkit-input-placeholder {
  font-weight: 300;
  color: #bfd5ec;
  font-size: 13px;
  font-style: italic;
}
.seach-china input::-moz-placeholder {
  font-weight: 300;
  color: #bfd5ec;
  font-size: 13px;
  font-style: italic;
}
.seach-china input::-ms-input-placeholder {
  font-weight: 300;
  color: #bfd5ec;
  font-size: 13px;
  font-style: italic;
}
.seach-button {
  width: 80px;
}
.btn-18 {
  background: #fe6701;
  border: 1px solid #bfd5ec;
  border-radius: 20px;
  height: 35px;
  width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btn-18:hover {
  opacity: 0.8;
}

.sub-select-mall {
  position: absolute;
  min-height: 100px;
  background-color: #fff;
  width: 102px;
  left: 0px;
  top: 35px;
}
.sub-select-mall .image {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
}
.sub-select-mall .image:hover {
  background-color: #f2f2f2;
  transition: all 0.3s;
}
.add-border-99 {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
/* content right */
.content-right-db .search input {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.content-right-db .content-search {
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow-y: scroll;
  height: 100vh;
}
.circle {
  width: 32px;
  height: 32px;
  background: #00A18F;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #00A18F;
}
.contentPost {
  padding: 21px 7px 11px 8px;
  border-bottom: 1px solid #B7B7B7;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #575757;
  cursor: pointer;
}
.contentPost:hover {
  background: #00A18F;
  color: #fff;
}

.contentPost .line-1 {
  text-decoration: none !important
}


.contentPost:hover span {
  color: #fff !important;
  text-decoration: none !important

}
.contentPost:hover > .circle {
  border: 1px solid #fff;
}
/* .contentPost:hover > .circle {
  background: #fff;
}  */


/* // nav menu */
nav ul{
    display: flex;
    padding: 0;
    margin: 0;
}

nav> ul li{
    list-style: none; /* bỏ dấu chấm mặc định của li */
    /* kẻ đường bên trái và bên phải của li */
    /* border-right: 1px solid #ccc;
    border-left:1px solid #333; */
}

nav> ul li{
    display: block;
    padding: 0 25px;
    line-height: 50px;
    text-decoration: none;
}

/*menu con*/
/*Ẩn các menu con cấp 1,2,3*/
nav li ul{
    display: none;
    min-width: 350px;
    position: absolute;
}

nav li>ul li{
    width: 100%;
    border: none;
    background: #fff;
    text-align: left;
}
.nav-lv {
  width: 220px;
}
.nav-lv:hover {
  background: #2196f3;
}
.nav-lv:hover > div > span {
  font-weight: 700;
  color: #fff;
}


nav  li:hover > ul{
    display:  block;
}
/*Hiển thị menu con cấp 2,3,4 bên cạnh phải*/
nav > ul li > ul li >ul{
    margin-left: 245px;
    margin-top: -48px;
}
.icon-down {
  padding: 0 10px;
}
</style>
